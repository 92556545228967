import { configureStore } from '@reduxjs/toolkit';
import { createWrapper } from 'next-redux-wrapper';
import appReducer, { initialState as initialAppState, AppState as AppSliceState } from './app';
import roomReducer, { RoomState } from './room';
import contentReducer, {
    initialState as initialContentState,
    ContentState,
} from 'packages/common/state/content';
import chatReducer, {
    initialState as initialChatState,
    ChatState,
} from 'packages/common/state/chat';
import userReducer, {
    initialState as initialUserState,
    UserState,
} from 'packages/common/state/user';
import messagesReducer, {
    MessagesState,
    initialState as initialMessagesState,
} from 'packages/common/state/messages';
import { isProduction } from 'utils/env';
import { LocalStorageKey, localStorageGet } from 'constants/localStorage';
import tracking from './middleware/tracking';
import roomParticipant from './middleware/roomParticipant';
import roomNotifications from './middleware/roomNotifications';
import { ChannelType } from 'packages/common/text-chat/types';
import localStorage from './middleware/localStorage';
import { PlaybackCookie } from '../utils/cookies';
import Cookie from 'js-cookie';
import { UserAgent } from 'next-useragent';

const getPreloadedState = (): Partial<AppState> => {
    if (typeof window !== 'undefined') {
        const roomCount = localStorageGet(LocalStorageKey.RoomCount, 0);
        const stageVolume = localStorageGet(LocalStorageKey.StageVolume, 100);
        const streamVolume = localStorageGet(LocalStorageKey.StreamVolume, 50);
        const pinnedHideTime = localStorageGet(LocalStorageKey.PinnedMessages, 0);
        const notificationsDisabled = localStorageGet(
            LocalStorageKey.ChatNotificationsDisabled,
            false
        );
        const messagesOpen = localStorageGet(LocalStorageKey.MessagesOpen, true);
        const accessToken = Cookie.get(PlaybackCookie.AccessToken);
        const deviceInfoCookie = Cookie.get(PlaybackCookie.DeviceInfo);
        const deviceInfo: UserAgent = deviceInfoCookie ? JSON.parse(deviceInfoCookie) : null;

        return {
            app: {
                ...initialAppState,
                roomCount,
                mobile: !!deviceInfo?.isMobile,
                osName: deviceInfo?.os,
                osVersion: deviceInfo?.osVersion,
                browserName: deviceInfo?.browser,
                browserVersion: deviceInfo?.browserVersion,
                deviceType: deviceInfo?.deviceType,
                deviceVendor: deviceInfo?.deviceVendor,
            },
            user: {
                ...initialUserState,
                accessToken,
            },
            chat: {
                ...initialChatState,
                volume: stageVolume,
            },
            content: {
                ...initialContentState,
                volume: streamVolume,
            },
            messages: {
                ...initialMessagesState,
                open: messagesOpen,
                notificationsEnabled: !notificationsDisabled,
                [ChannelType.Room]: {
                    ...initialMessagesState[ChannelType.Room],
                    pinnedHideTime,
                },
            },
        };
    }
};

const makeStore = () =>
    configureStore({
        reducer: {
            app: appReducer,
            room: roomReducer,
            content: contentReducer,
            chat: chatReducer,
            user: userReducer,
            messages: messagesReducer,
        },
        middleware: [roomNotifications, roomParticipant, localStorage, tracking],
        devTools: !isProduction(),
        preloadedState: getPreloadedState(),
    });

export type AppStore = ReturnType<typeof makeStore>;
export interface AppState {
    app: AppSliceState;
    room: RoomState;
    content: ContentState;
    chat: ChatState;
    user: UserState;
    messages: MessagesState;
}
export type AppDispatch = AppStore['dispatch'];
export const wrapper = createWrapper<AppStore>(makeStore, { debug: false });
