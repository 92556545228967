import { postData } from 'utils/api';
import packageJson from '../../package.json';
import { AnyAction, Dispatch, MiddlewareAPI } from 'redux';
import Router from 'next/router';
import { v4 } from 'uuid';
import { ReferralContext, REFERRAL_CONTEXT_NAME } from 'typescript/typings';
import { ShadowEventType } from 'packages/common/base/utils/tracking';
import { track, pageView, identifyUser, updateUser, trackingQueuePush } from 'state/app';
import Tracking, { RoomEntryType } from 'packages/common/base/utils/tracking';
import { isProduction } from '../../utils/env';
import { LocalStorageKey, localStorageGet, localStorageSet } from '../../constants/localStorage';
import { AppState } from '../store';
import { RoomSettings } from 'packages/common/api-client/types';

const canTrack = () => isProduction() && typeof window !== 'undefined' && !Router.query.recorder;

const getAnalyticsContext = (app: AppState['app']) => {
    return {
        active: true,
        app: {
            name: 'web',
            version: packageJson.version,
        },
        device: {
            type: app.deviceType,
            model: app.deviceModel,
            manufacturer: app.deviceVendor,
        },
        locale: navigator.language,
        os: {
            name: app.osName,
            version: app.osVersion,
        },
        page: {
            path: window.location.pathname,
            url: window.location.href,
            title: document.title,
            search: window.location.search,
            referrer: document.referrer,
        },
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
};

export const getAnonymousID = () => {
    let uid = localStorageGet(LocalStorageKey.Uid);

    if (!uid) {
        uid = v4();
        localStorageSet(LocalStorageKey.Uid, uid);
    }

    return uid;
};

const getRoomType = (settings?: RoomSettings) => {
    if (typeof settings?.openSpeak !== 'boolean') {
        return null;
    }

    return settings.openSpeak ? 'open' : 'stage';
};

const tracking =
    (store: MiddlewareAPI) => (next: Dispatch<AnyAction>) => async (action: AnyAction) => {
        const state = store.getState() as AppState;

        const getBaseProperties = () => {
            return {
                roomID: state.room.urlName,
                roomName: state.room.name,
                roomEntryType:
                    state.app.roomEntryType === null
                        ? RoomEntryType.Direct
                        : state.app.roomEntryType,
                roomType: getRoomType(state.room.settings),
                name: state.user.name,
                userID: state.user.id,
                camera: state.chat.camera.enabled && state.chat.camera.permission,
                mic: state.chat.mic.enabled && state.chat.mic.permission,
                participantID: state.room.participantID,
                participantCount: state.room.participants.allIds.length,
                userCount: Object.keys(state.room.userParticipants).length,
                roomSize: state.room.members.allIds.length,
                version: packageJson.version,
                platform: 'web',
                osName: state.app.osName,
                osVersion: state.app.osVersion,
                browserName: state.app.browserName,
                browserVersion: state.app.browserVersion,
                streamerID: state.room.contentStreamMetadataState?.userID,
                chatOpen: state.messages.open,
                popoutChat: state.messages.popout,
            };
        };

        const getUserIds = () => {
            return {
                userId: state.user.id,
                anonymousId: getAnonymousID(),
            };
        };

        const getUserTraits = () => {
            const { email, name } = state.user;

            const traits: any = {
                email,
                name,
            };

            if (email) {
                traits.dataString = Tracking.encodeUserData({
                    email,
                    userName: name,
                });
            }

            return traits;
        };

        const queued = () => {
            if (!state.user.initialized) {
                store.dispatch(trackingQueuePush(action));
                return true;
            }

            return false;
        };

        switch (action.type) {
            case identifyUser.toString(): {
                if (queued()) break;

                const data = action.payload || {};
                const payload = {
                    type: ShadowEventType.Identify,
                    traits: {
                        ...getUserTraits(),
                        ...data,
                    },
                    context: getAnalyticsContext(state.app),
                    ...getUserIds(),
                };

                if (canTrack()) {
                    await postData('/api/shadow', payload);
                } else {
                    console.log('Identify', payload);
                }

                break;
            }
            case updateUser.toString(): {
                if (queued()) break;

                const data = action.payload || {};
                const payload = {
                    type: ShadowEventType.Identify,
                    traits: {
                        ...getUserTraits(),
                        ...data,
                    },
                    context: getAnalyticsContext(state.app),
                    ...getUserIds(),
                };

                if (canTrack()) {
                    await postData('/api/shadow', payload);
                } else {
                    console.log('Update Visitor', payload);
                }
                break;
            }
            case track.toString(): {
                if (queued()) break;

                const { event, ...data } = action.payload;
                const augmentedData = {
                    ...getBaseProperties(),
                    ...data,
                };

                const payload = {
                    type: ShadowEventType.Track,
                    event,
                    properties: augmentedData,
                    context: getAnalyticsContext(state.app),
                    ...getUserIds(),
                };

                if (canTrack()) {
                    await postData('/api/shadow', payload);
                } else {
                    console.log('Track', event, payload);
                }

                break;
            }
            case pageView.toString(): {
                if (queued()) break;

                const { title, ...properties } = action.payload;
                const { p } = Router.query;

                const augmentedData = {
                    ...getBaseProperties(),
                    ...properties,
                    referralLink:
                        typeof p !== 'undefined'
                            ? REFERRAL_CONTEXT_NAME[<ReferralContext>parseInt(<string>p)]
                            : null,
                };

                const payload = {
                    type: ShadowEventType.Page,
                    name: title || document.title,
                    properties: augmentedData,
                    context: getAnalyticsContext(state.app),
                    ...getUserIds(),
                };

                if (canTrack()) {
                    await postData('/api/shadow', payload);
                } else {
                    console.log('Pageview', title, payload);
                }

                break;
            }
            default:
        }

        return next(action);
    };

export default tracking;
