import styled, { css } from 'styled-components';
import { rem, darken, lighten } from 'polished';

type Format =
    | 'neutral'
    | 'success'
    | 'negative'
    | 'warning'
    | 'success_primary'
    | 'negative_primary'
    | 'neutral_light'
    | 'neutral_dark';
type Size = 'sm' | 'md' | 'lg' | 'xl';

const ColorStyles = (fg: string, bg: string, bgHover?: string) => css`
    color: ${fg};
    background-color: ${bg};

    svg {
        transition: fill 50ms ease;
    }

    ${bgHover &&
    css`
        @media (hover: hover) {
            &:hover:not(:disabled) {
                background-color: ${bgHover};
            }
        }
    `}
`;

interface FilledButton {
    format?: Format;
    size?: Size;
    icon?: boolean;
    transparent?: boolean;
    hover?: boolean;
    disabled?: boolean;
}

export const FilledButtonStyles = css<FilledButton>`
    ${({ theme }) => theme.typography.FontMedium};
    font-size: ${rem(13)};
    letter-spacing: ${rem(0.15)};
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 50ms ease, background-color 50ms ease, color 50ms ease;
    flex-shrink: 0;
    white-space: nowrap;
    user-select: none;

    svg {
        height: ${rem(16)};
        width: ${rem(16)};
        margin-right: ${rem(5)};
        flex-shrink: 0;
        transition: fill 50ms ease;
    }

    &:focus:not(.focus-visible) {
        outline: none;
        box-shadow: none;
    }

    &.focus-visible {
        box-shadow: 0px 0px 0px ${rem(1)} ${({ theme }) => theme.palette.White};
    }

    &:disabled {
        cursor: default;
        opacity: 0.6;
    }

    ${({ format = 'default', theme, hover = true }) => {
        switch (format) {
            case 'success_primary':
                return ColorStyles(
                    theme.palette.White,
                    theme.palette.Green,
                    hover ? darken(0.1, theme.palette.Green) : null
                );
            case 'success':
                return ColorStyles(
                    theme.palette.Green,
                    theme.palette.GreenTransparent1,
                    hover ? theme.palette.GreenTransparent2 : null
                );
            case 'negative':
                return ColorStyles(
                    theme.palette.Red,
                    theme.palette.RedTransparent1,
                    hover ? theme.palette.RedTransparent2 : null
                );
            case 'negative_primary':
                return ColorStyles(
                    theme.palette.White,
                    theme.palette.Red,
                    hover ? darken(0.1, theme.palette.Red) : null
                );
            case 'warning':
                return ColorStyles(
                    theme.palette.Yellow,
                    theme.palette.YellowTransparent1,
                    hover ? theme.palette.YellowTransparent3 : null
                );
            case 'neutral_light':
                return ColorStyles(
                    theme.palette.White,
                    theme.palette.MedGrey3,
                    hover ? lighten(0.05, theme.palette.MedGrey2) : null
                );
            case 'neutral_dark':
                return ColorStyles(
                    theme.palette.LightGrey1,
                    theme.palette.DarkGrey1,
                    hover ? lighten(0.05, theme.palette.DarkGrey1) : null
                );
            default:
                return ColorStyles(
                    theme.palette.White,
                    theme.palette.MedGrey4,
                    hover ? theme.palette.MedGrey3 : null
                );
        }
    }}

    ${({ size = 'md', icon = false }) => {
        switch (size) {
            case 'xl':
                return css`
                    height: ${rem(64)};
                    padding: 0 ${icon ? 0 : rem(40)};
                    width: ${icon ? rem(64) : 'auto'};
                    border-radius: ${rem(32)};
                    font-size: ${rem(16)};

                    svg {
                        margin-right: ${rem(10)};
                        height: ${rem(20)};
                        width: ${rem(20)};
                    }
                `;
            case 'lg':
                return css`
                    height: ${rem(40)};
                    padding: 0 ${icon ? 0 : rem(20)};
                    width: ${icon ? rem(40) : 'auto'};
                    border-radius: ${rem(20)};

                    svg {
                        margin-right: ${rem(8)};
                        height: ${rem(20)};
                        width: ${rem(20)};
                    }
                `;
            case 'md':
                return css`
                    height: ${rem(32)};
                    padding: 0 ${icon ? 0 : rem(12)};
                    width: ${icon ? rem(32) : 'auto'};
                    border-radius: ${rem(16)};

                    svg {
                        margin-right: ${rem(5)};
                    }
                `;
            default:
                return css`
                    height: ${rem(28)};
                    padding: 0 ${icon ? 0 : rem(8)};
                    width: ${icon ? rem(28) : 'auto'};
                    border-radius: ${rem(14)};

                    svg {
                        height: ${rem(14)};
                        width: ${rem(14)};
                    }
                `;
        }
    }}

    ${({ icon }) =>
        icon &&
        css`
            border-radius: 50%;

            svg {
                margin-right: 0;
            }
        `}

    ${({ transparent }) =>
        transparent &&
        css`
            background-color: transparent;
        `}
`;

const FilledButton = styled.button<FilledButton>`
    ${FilledButtonStyles}
`;

export default FilledButton;
